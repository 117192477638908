import Vue from 'vue'
import Vuex from 'vuex'

import auth from '../auth/store/store'
import layout from '../layout/store/store'
import messages from '../messages/store/store'
import tools from '../tools/store/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    layout,
    messages,
    tools,
  },
})
