import http from './http'

/**
 * The body of the server's response.
 * @typedef {Object} ServerResponse
 * @property {boolean} error=false
 * @property {(Object|string)} message
 * @property {!number} status
 */

const { NODE_ENV, VUE_APP_SERVER_HOST_LOCAL, VUE_APP_SERVER_HOST_REMOTE } = process.env

const devEnvs = ['development', 'test']
const SERVER_HOST = (devEnvs.indexOf(NODE_ENV) !== -1 && VUE_APP_SERVER_HOST_LOCAL) || VUE_APP_SERVER_HOST_REMOTE

const server = {
  host: `${SERVER_HOST}/app`,
  paths: {
    base: () => '/',
    decode: (tokenType) => `/decode/${tokenType}`,
    generate: (tokenType) => `/generate/${tokenType}`,
    health: () => '/health',
    verify: (tokenType) => `/verify/${tokenType}`,
  },
  /**
   * Sends a GET request to server endpoints.
   * @param {string} path A path on the application server, e.g. /generate.
   * @param {Object=} additionalHeaders
   * @returns {Promise<ServerResponse>}
   */
  get(path, additionalHeaders) {
    const config = http.config.get(true, additionalHeaders)
    return http.get(`${this.host}${path}`, config)
  },
  /**
   * Sends a POST request to server endpoints.
   * @param {string} path A path on the application server, e.g. /generate
   * @param {Object=} additionalHeaders
   * @returns {Promise<ServerResponse>}
   */
  post(path, additionalHeaders) {
    const config = http.config.get(true, additionalHeaders)
    return http.post(`${this.host}${path}`, config)
  },
  /**
   * Makes a request to the server's health endpoint.
   * @returns {Promise<ServerResponse>}
   */
  health() {
    return this.get(this.paths.health())
  },
  /**
   * Prompts the server to generate a token and return it or an error object.
   * @returns {Promise<ServerResponse>}
   */
  generate(tokenType) {
    return this.post(this.paths.generate(tokenType))
  },
  /**
   * Decodes the token if it is valid, or returns an error object if it's invalid.
   * @param {object} additionalHeaders For Tableau tokens, set the Tableau header with the token to decode.
   * @returns {Promise<ServerResponse>}
   */
  decode(tokenType, additionalHeaders) {
    return this.get(this.paths.decode(tokenType), additionalHeaders)
  },
  /**
   * Verifies the token if it is valid, or returns an error object if it's invalid.
   * @param {object} additionalHeaders For Tableau tokens, set the Tableau header with the token to verify.
   * @returns {Promise<ServerResponse>}
   */
  verify(tokenType, additionalHeaders) {
    return this.get(this.paths.verify(tokenType), additionalHeaders)
  },
}

export default server
