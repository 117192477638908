/**
 * Configures Firebase and oversees its usage
 */

import { initializeApp } from 'firebase/app'
import { getAnalytics, isSupported, logEvent, setUserId, setUserProperties } from 'firebase/analytics'
import {
  getAuth, GoogleAuthProvider as AuthProvider, onAuthStateChanged, signInWithPopup, signInWithRedirect, signOut,
} from 'firebase/auth'
import { collection, doc, enableIndexedDbPersistence, getDoc, getDocs, getFirestore, setDoc } from 'firebase/firestore'

const {
  NODE_ENV,
  VUE_APP_FIREBASE_STORAGE: FIREBASE_STORAGE,
  VUE_APP_FIREBASE_API_KEY: FIREBASE_API_KEY,
  VUE_APP_FIREBASE_APP_ID: FIREBASE_APP_ID,
  VUE_APP_FIREBASE_MEASUREMENT_ID: FIREBASE_MEASUREMENT_ID,
  VUE_APP_FIREBASE_MESSAGING_SENDER_ID: FIREBASE_MESSAGING_SENDER_ID,
  VUE_APP_FIREBASE_SCOPE: FIREBASE_SCOPE,
  VUE_APP_GOOGLE_CLIENT_ID: GOOGLE_CLIENT_ID,
  VUE_APP_GOOGLE_PROJECT: GOOGLE_PROJECT,
} = process.env

const FIREBASE_CONFIG = {
  apiKey: FIREBASE_API_KEY,
  appId: FIREBASE_APP_ID,
  authDomain: `${GOOGLE_PROJECT}.firebaseapp.com`,
  clientId: GOOGLE_CLIENT_ID,
  databaseURL: `https://${GOOGLE_PROJECT}.firebaseio.com`,
  projectId: GOOGLE_PROJECT,
  storageBucket: FIREBASE_STORAGE,
  measurementId: FIREBASE_MEASUREMENT_ID,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  scopes: FIREBASE_SCOPE,
}

// Initialize Firebase & export it.
const firebaseApp = initializeApp(FIREBASE_CONFIG)

// Save configured Firestore references.
let analytics = null

isSupported().then((isSupportedApp) => {
  if (isSupportedApp) analytics = getAnalytics(firebaseApp)
})

const Firestore = getFirestore(firebaseApp)
const Fireauth = getAuth(firebaseApp)

const isDevEnv = /dev/.test(NODE_ENV)

if (!isDevEnv) {
  enableIndexedDbPersistence(Firestore, {
    synchronizeTabs: true,
  }).catch((err) => {
    const logger = console

    if (err.code === 'failed-precondition') {
      logger.warn(`Firebase Persistence Failed Precondition\n
        Multiple tabs are open yet persistence can be enabled
        in only one tab at a time.`)
    } else if (err.code === 'unimplemented') {
      logger.warn('The current browser does not support Firebase persistence.')
    } else {
      logger.info('Firebase Persistence', err)
    }
  })
}

const firecollection = {
  docs: () => collection(Firestore, 'app/docs/links'),
  message: () => collection(Firestore, 'message'),
  organization: () => collection(Firestore, 'organization'),
  person: () => collection(Firestore, 'person'),
  error(catcher) {
    return (FirebaseError) => {
      const error = {
        code: FirebaseError.code || 404,
        layer: 'app/data',
        message: FirebaseError.message || 'Record Not Found',
      }
      return catcher({ error })
    }
  },
  async collection(collectionName, catcher) {
    const currentCollection = this[collectionName]()
    const docs = await getDocs(currentCollection).catch(this.error(catcher))
    const results = []

    // Firestore responses are not real arrays; Instead, they offer a custom forEach method.
    docs.forEach((document) => {
      const result = document.data()

      if (result) result.docId = document.id

      results.push(result)
    })

    return results
  },
  async get(collectionName, docId, catcher) {
    const currentDoc = doc(Firestore, collectionName, docId)
    const document = await getDoc(currentDoc).catch(this.error(catcher))
    const response = (document && document.exists() && document.data()) || null

    if (response) response.docId = document.id

    return response
  },
  async set(collectionName, docId, newData, options) {
    const currentCollection = this[collectionName]()
    return setDoc(doc(currentCollection, docId), newData, options)
  },
}

const fireanalytics = {
  event(eventName, eventData) {
    if (analytics) {
      logEvent(analytics, eventName, eventData)
    }
  },
  async setUser(analyticsData) {
    const { analyticsUser, personId } = analyticsData

    setUserId(analytics, personId)

    return setUserProperties(analytics, analyticsUser)
  },
}

const fireauth = {
  onAuthStateChanged(fnSuccess, fnError) {
    return onAuthStateChanged(Fireauth, fnSuccess, fnError)
  },
  signInWithPopup() {
    const provider = new AuthProvider()
    return signInWithPopup(Fireauth, provider)
  },
  signInWithRedirect() {
    const provider = new AuthProvider()
    return signInWithRedirect(Fireauth, provider)
  },
  signOut() {
    return signOut(Fireauth)
  },
}

export {
  fireauth,
  fireanalytics,
  AuthProvider,
  firecollection,
}
